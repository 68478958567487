import React from 'react';
import Marquee from "react-fast-marquee";
import r1 from "../assets/img/partners/r1.webp";
import r2 from "../assets/img/partners/r2.webp";
import r4 from "../assets/img/partners/r4.webp";
import r5 from "../assets/img/partners/r5.webp";
import r6 from "../assets/img/partners/r6.webp";
import r7 from "../assets/img/partners/r7.webp";

import "./topRequiters.css"



function TopRequiters() {




  return (
    <>

      <section id="page-need_guidance-section" className="need-guidance-form flex items-end md:items-center relative z-[11] -mt-[2px] overflow-hidden" style={{ marginBottom: "60px" }}>

        <div className="min-h-full h-full w-full  relative z-[3] py-4 rec">
          <div className="sm:max-w-[480px] lg:max-w-[560px]" style={{ maxWidth: "100%" }}>


            <h1 className="mainHeadingTotal">OUR TOP RECUITERS</h1>


            <Marquee speed={100} style={{ Padding: "0px 20px" }} className='recuitersList'>
              <img alt="rec1" src={r1} width={"300"} height={"81"} />
              <img alt="rec2" src={r2} width={"300"} height={"81"} />
              <img alt="rec4" src={r4} width={"300"} height={"81"} />
              <img alt="rec5" src={r5} width={"300"} height={"81"} />
              <img alt="rec9" src={r6} width={"300"} height={"81"} />
              <img alt="rec10" src={r7} width={"300"} height={"81"} />

            </Marquee>


          </div>
        </div>
      </section>

    </>
  );
}

export default TopRequiters;
