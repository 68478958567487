import React, { useCallback, useEffect, useRef } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './EmblaCarouselArrowButtonsBuzz'
import { useDotButton } from './EmblaCarouselDotButtonBuzz'
import b1 from "../assets/img/Unreal.webp";
import b2 from "../assets/img/board_game.webp";
import b3 from "../assets/img/global.webp";
const TWEEN_FACTOR_BASE = 0.2

const EmblaCarousel = (props) => {
  const { options } = props
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
  const tweenFactor = useRef(0)
  const tweenNodes = useRef([])

  const {} =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  const setTweenNodes = useCallback((emblaApi) => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector('.embla__parallax__layerb')
    })
  }, [])

  const setTweenFactor = useCallback((emblaApi) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length
  }, [])

  const tweenParallax = useCallback((emblaApi, eventName) => {
    const engine = emblaApi.internalEngine()
    const scrollProgress = emblaApi.scrollProgress()
    const slidesInView = emblaApi.slidesInView()
    const isScrollEvent = eventName === 'scroll'

    emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
      let diffToTarget = scrollSnap - scrollProgress
      const slidesInSnap = engine.slideRegistry[snapIndex]

      slidesInSnap.forEach((slideIndex) => {
        if (isScrollEvent && !slidesInView.includes(slideIndex)) return

        if (engine.options.loop) {
          engine.slideLooper.loopPoints.forEach((loopItem) => {
            const target = loopItem.target()

            if (slideIndex === loopItem.index && target !== 0) {
              const sign = Math.sign(target)

              if (sign === -1) {
                diffToTarget = scrollSnap - (1 + scrollProgress)
              }
              if (sign === 1) {
                diffToTarget = scrollSnap + (1 - scrollProgress)
              }
            }
          })
        }

        const translate = diffToTarget * (-1 * tweenFactor.current) * 100
        const tweenNode = tweenNodes.current[slideIndex]
        tweenNode.style.transform = `translateX(${translate}%)`
      })
    })
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    setTweenNodes(emblaApi)
    setTweenFactor(emblaApi)
    tweenParallax(emblaApi)

    emblaApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenParallax)
      .on('scroll', tweenParallax)
      .on('slideFocus', tweenParallax)
  }, [emblaApi, tweenParallax])

  return (
    <div className="emblab">
      <div className="embla__viewportb" ref={emblaRef}>
        <div className="embla__containerb">
          
            <div className="embla__slideb" key={1}>
              <div className="embla__parallaxb">
                <div className="embla__parallax__layerb">
                  <img
                    className="embla__slide__imgb embla__parallax__imgb"
                    src={b1}
                    alt="Your alt text"
                  />
                  <div className="buzzdiv">
                    <h1>Unreal Workshop</h1>
                  </div>
                  
                </div>
              </div>
            </div>

            <div className="embla__slideb" key={2}>
              <div className="embla__parallaxb">
                <div className="embla__parallax__layerb">
                  <img
                    className="embla__slide__imgb embla__parallax__imgb"
                    src={b2}
                    alt="Your alt text"
                  />
                   <div className="buzzdiv">
                    <h1>Board Game Workshop</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="embla__slideb" key={3}>
              <div className="embla__parallaxb">
                <div className="embla__parallax__layerb">
                  <img
                    className="embla__slide__imgb embla__parallax__imgb"
                    src={b3}
                    alt="Your alt text"
                  />
                   <div className="buzzdiv">
                    <h1>Global Game Jam </h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="embla__slideb" key={4}>
              <div className="embla__parallaxb">
                <div className="embla__parallax__layerb">
                  <img
                    className="embla__slide__imgb embla__parallax__imgb"
                    src={b1}
                    alt="Your alt text"
                  />
                   <div className="buzzdiv">
                    <h1>Unreal Workshop</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="embla__slideb" key={5}>
              <div className="embla__parallaxb">
                <div className="embla__parallax__layerb">
                  <img
                    className="embla__slide__imgb embla__parallax__imgb"
                    src={b2}
                    alt="Your alt text"
                  />
                   <div className="buzzdiv">
                    <h1>Board Game Workshop</h1>
                  </div>
                </div>
              </div>
            </div>

            <div className="embla__slideb" key={6}>
              <div className="embla__parallaxb">
                <div className="embla__parallax__layerb">
                  <img
                    className="embla__slide__imgb embla__parallax__imgb"
                    src={b3}
                    alt="Your alt text"
                  />
                   <div className="buzzdiv">
                    <h1>Global Game Jam </h1>
                  </div>
                </div>
              </div>
            </div>
         
        </div>
      </div>

      <div className="embla__controlsb">
        <div className="embla__buttonsb">
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>

      
      </div>
    </div>
  )
}

export default EmblaCarousel
