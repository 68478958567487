import React from 'react';
// import c1 from "../assets/img/c1.png";
// import c2 from "../assets/img/c2.png";
// import c3 from "../assets/img/c1.png";
// import c4 from "../assets/img/c4.png";
// import f1 from "../assets/img/c-logo.svg";
import fimg1 from "../assets/img/advgd.webp";
import f2 from "../assets/img/g-logo.svg";
import fimg2 from "../assets/img/adv3d.jpg";
import fimg3 from "../assets/img/gadu.webp";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import msc from "../assets/img/gadp.jpg";



import "./FeaturedProduct.css"


function HomeContent() {

  
  return (
    <>
      <section id="page-need_guidance-section" className="need-guidance-form flex items-end md:items-center md:min-h-[680px] lg:min-h-[800px] relative z-[11] -mt-[2px] overflow-hidden bgw" data-locomotive-section-id="page-need_guidance" data-locomotive-section-type="need_guidance">
        <div className="min-h-full h-full w-full container-full relative z-[3] py-4 md:py-8">
          <div className="d">
            <h1 className="mainHeadingTotal">FEATURED COURSES</h1>
            <div className="">
              <div className="grid-cols-1 sm:grid-cols-2 grid gap-3 mt-3 sm:mt-5 lg:mt-6 card-list">
              <Link to="/courses/advanced-diploma-in-game-development/"> 
                <li className="h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group ">
                  <div className="z-10 relative flex flex-col justify-between h-full">
                    <div className="flex justify-between items-start">
                      <div>
                        <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                      </div>
                    </div>
                    <div className="flex justify-center flex-col items-center text-txt100">
                      <p className='hdf'><Link style={{color: "#fff"}} to="/courses/advanced-diploma-in-game-development/"> Advanced Diploma in <br />Game Development</Link></p>
                      <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center">
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 h-full w-full image-card imgBx">
                  <Link to="/courses/advanced-diploma-in-game-development/"> <img src={fimg1} srcset={fimg1} alt="Concept Art" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" /></Link>
                  </div>
                  <Link to="/courses/advanced-diploma-in-game-development/" className="Ram absolute top-0 left-0 h-full w-full z-[11]"></Link>
                </li>
                </Link>
                <Link to="/courses/advanced-diploma-in-3d-game-art-digital-sculpting/">
                <li className="h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group">
                  <div className="z-10 relative flex flex-col justify-between h-full">
                    <div className="flex justify-between items-start">
                      <div>
                        <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                      </div>
                    </div>
                    <div className="flex justify-center flex-col items-center text-txt100">
                      <p className='hdf'><Link style={{color: "#fff"}} to="/courses/advanced-diploma-in-3d-game-art-digital-sculpting/">Advanced Diploma in 3D Game Art & <br /> Digital Sculpting</Link></p>
                      <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center">
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 h-full w-full image-card">
                    <img src={fimg2} srcset={fimg2} alt="Game Art" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                  </div>
                  <Link to="/courses/advanced-diploma-in-3d-game-art-digital-sculpting/" className="absolute top-0 left-0 h-full w-full z-[11]" target="_blank"></Link>
                </li>
                </Link>
                <Link to="/courses/diploma-in-game-development-with-unity/">
                <li className="h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group mb-sty">
                  <div className="z-10 relative flex flex-col justify-between h-full">
                    <div className="flex justify-between items-start">
                      <div>
                        <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                      </div>
                    </div>
                    <div className="flex justify-center flex-col items-center text-txt100">
                      <p className='hdf'><Link style={{color: "#fff"}} to="/courses/diploma-in-game-development-with-unity/">Diploma in Game Development <br /> with Unity</Link></p>
                      <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center">
                      </p>
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 h-full w-full image-card">
                    <img src={fimg3} srcset={fimg3} alt="VFX" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                  </div>
                  <Link to="/courses/diploma-in-game-development-with-unity/" className="absolute top-0 left-0 h-full w-full z-[11]" target="_blank"></Link>
                </li>
                </Link>
                <Link to="/courses/diploma-in-game-design-production/">
                <li className="h-[320px] w-full bg-gradient200 p-2 rounded-large overflow-hidden relative group mb-sty">
                  <div className="z-10 relative flex flex-col justify-between h-full">
                    <div className="flex justify-between items-start">
                      <div>
                        <img src={f2} alt="logo" width="49" height="32" className="h-4" />
                      </div>
                    </div>
                    <div className="flex justify-center flex-col items-center text-txt100">
                      <p className='hdf'><Link style={{color: "#fff"}} to="/courses/diploma-in-game-design-production/">Diploma in Game Design <br /> & Production</Link></p>
                      <p className="px-1 lg:px-2 mt-0 v-p400 ease-in-brand duration-600 opacity-0 max-h-0 group-hover:max-h-[80px] group-hover:opacity-100 group-hover:mt-3 text-center"></p>
                    </div>
                  </div>
                  <div className="absolute top-0 left-0 h-full w-full image-card">
                    <img src={msc} srcset={msc} alt="Concept Art" className="rounded-large object-cover h-full w-full scale-[1.01] group-hover:scale-[1.12] ease-in-brand duration-600" loading="lazy" />
                  </div>
                  <Link to="/courses/diploma-in-game-design-production/" className="absolute top-0 left-0 h-full w-full z-[11]" target="_blank"></Link>
                </li>
                </Link>
              </div>
              <Link style={{display: "flex", justifyContent: "center"}} to={"/courses/"}> <button style={{ width: "300px", float: "right", marginTop: "30px" }} className='three button brand size200 w-full sm:w-auto' type="button">View All Courses <FiArrowRight style={{ marginLeft: "7px" }} /></button></Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeContent;
